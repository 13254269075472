.MessagePage_container{
  display: flex;
  width: 100%;
  height: 100%
  
}
.MessagePage_listOfUsers{
  max-width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-color: rgba(255, 245, 222,0.9);
  border-right: 1px solid #ccc;
}
.MessagePage_listOfFriends{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-color: rgba(255, 245, 222,0.9);
  border-right: 1px solid #ccc;
  padding-bottom: 10px;
  border-bottom: solid black;
}
.MessagePage_listOfFriends h2{
  margin:15px auto;
}
.MessagePage_listOfRequests{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-color: rgba(255, 245, 222,0.9);
  border-right: 1px solid #ccc;
  padding-bottom: 10px;
  border-bottom: solid black;
}
.MessagePage_listOfRequests h2{
  margin:15px auto;
}


.MessagePage_chatArea{
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  background-color: rgba(255, 245, 222,0.8);
  position: relative;
}
.MessagePage_chatHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  flex-grow: 1;
  background-color: rgba(255, 245, 222,0.9);
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
  font-family: sans-serif;
}
.MessagePage_chatControls{
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-height: 45px;
  border-radius: 5px;

  
}
.MessagePage_chatControls textarea{
  display: flex;
  flex-grow: 1;
  width: 90%;
}
.MessagePage_chatControls button{
  display: flex;
  width: 10%;
}

.MessagePage_displayName{
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.MessagePage_displayName.active{
  background: #ccc;
  
}

.MessagePage_displayName .MessagePage_displayPic{
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 25px;
}

.MessagePage_displayName .displayPic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MessagePage_messageStyle{
  background: rgb(82, 92, 99);
  color: white;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 7px;

  
}
.MessagePage_messageSections{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.MessagePage_selectUser{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.MessagePage_onlineStatus{
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #16e316;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #16e316;
}

.MessagePage_onlineStatusoff{
  display: inline-block;
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 5px;
  box-shadow: 0 0 0 0;
}