.Login_main{
    
    
    min-height: 100vh;
    min-width: 100vw;

    
  
}
.Login_background{
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8px);
    background-image: url(".//background.png");
    min-height: 100vh;
    min-width: 100vw;
    z-index: -1;
}
.Login_CommonCard{
    
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 70px;
    padding: 25px 25px;
    background-color: #fff;
    max-width: 300px;
    border-radius: 5px;
    position: fixed;
    top: 30%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
    z-index: 2;
    
    
}
.Login_Starkez{
    padding: 4px 10px;
}
.Login_Starkez h2{
    color: rgba(0,0,0,0.4);
}

.Login_Starkez:hover h2{
color: rgba(0,0,0,1);
cursor: pointer;
}
.Login_TextField{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login_LoginButton{
    margin: 10px 0px 5px;
}
@media (max-width:768px){
    .Login_LoginButton{
        margin-bottom: 5px ;
        
    }
}

.Login_LoginButton img{
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
}

.Login_LoginButton p{
    font-size: 12px;
}

.Login_SubmitButton{
    margin: 10px 0;
    min-width: 150px;
    display: flex;
}
.Login_SubmitButton Button:nth-child(2){
    margin-left: 10px;
    border-radius: 5px;
}