
.Commoncard {
    text-align: center;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 2px 10px;
    background-color: rgba(0 0 0 15%);
    background-color: #fff;
    background-color: #eeeeee;
    border-radius: 5px;
    position: relative;
    border: none;
    max-height: 100%;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0/ 20%);
  }
.itemc{
    grid-area:Study;
}