.Layout_Layout{
   
    min-height: 100vh;
}
.Layout_NavButton{
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 20;
    padding: 2px 3px;
}
@media(max-width:768px) {
    .Layout_NavButton{
        left: 5px;
        top: 11px;
        
    }
}
.Layout_NavButton img{
    width: 20px;
    cursor: pointer;
    
}
@media(max-width:768px) {
    .Layout_NavButton img{
       width: 15px;
        
    }
}
.Layout_NavButton:hover{
   
    background-color: rgba(255, 226, 255, 0.6);
    color: rgba(228, 228, 228, 0.8);
    transform: translate3d(0,2px,0);
    border-radius: 5px;
  
}
