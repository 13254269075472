.CommonCard{
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 20px 100px;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
}

.Course{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.CourseName{
    display: flex;
    flex-grow: 1;
}

@media (max-width:768px) {
    .CourseName{
        display: block;
    }
}

.CourseDesc{
    display: flex;
    
}
@media (max-width:768px) {
    .CourseDesc{
        display: block;
    }
}
.Exams{
    display: flex;
    border-top: solid rgba(0,0,0,0.3);
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.ExamDates{
    display: flex;
    
}

@media (max-width:768px) {
    .ExamDates{
        display: block;
    }
}

.Button{
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
}
