.NavigationBar_Layout{
    display: grid;
    grid-template-columns: minmax(0, 4fr) minmax(0, 25fr);
    grid-template-rows: minmax(35px,45px) minmax(0, 4fr);
    grid-template-areas: 
    "main main"
    "body body";
    column-gap: 0px;
    row-gap: 0px;
    height: 100vh;
}
@media(max-width:768px) {
    .NavigationBar_Layout{
        grid-template-rows: minmax(35px,45px) minmax(0, 25fr);
    }
}
.NavigationBar_Body{
    grid-area: body;
    display: flex;
    max-height: 100%;
    flex-grow: 1;
    
}
.NavigationBar_MenuBar{
    grid-area: main;
    height: 45px;
    padding-left: 10px;
    display: flex;
}

.NavigationBar_NavTab{
    display: flex;
    align-items: center;
    padding-left: 10px;
    
}

@media (max-width:768px) {
    .NavigationBar_NavTab{
        padding-left: 3px;
        font-size: 12px;
        font-weight: bold;
    }
}
.NavigationBar_NavMenue{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
@media (max-width:768px) {
    .NavigationBar_NavMenue{
        margin-left: 7px;
    }
}
.NavigationBar_NavMenue button{
    padding: 0 3px;
    display: flex;
    min-width: 50px;
    margin: 0 10px;
    margin-top: 5px;
}

.NavigationBar_NavMenue button img{
    height: 25px;
    
}
@media (max-width:768px){
    .NavigationBar_NavMenue button:first-child{
       
        min-width: 35px;
    }
}
@media (max-width:768px){
    .NavigationBar_NavMenue button img:first-child{
        
       height: 15px;
        
        
    }
}
@media (max-width:768px){
    .NavigationBar_NavMenue button img{
        height: 15px;
    }
}
@media (max-width:768px){
    .NavigationBar_NavMenue button{
        margin: 0 5px;
        min-width: 40px;
    }
}
.NavigationBar_NavMenue a{
    text-decoration: none ;
    font-size: 14px;
    align-items: center;
    display: flex;
    padding: 5px 10px;
    margin: 0px 0px;
    color: rgb(252, 252, 252);
    justify-content: center;
    font-weight: bold;
}
@media (max-width:768px) {
    .NavigationBar_NavMenue a{
        margin: 5px 0px;
        padding: 0 1px;
        font-size: 10px;
        font-weight: bold;
    }
}

.NavigationBar_NavMenue button:hover {
    text-decoration: none;
    background-color: rgba(255, 226, 255, 0.6);
    color: rgba(0, 0, 0, 0.8);
    transform: translate3d(0,-2px,0);
    border-radius: 5px;
    
  
  }

.NavigationBar_User{
    display: blocl;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: flex-end;
    
}
.NavigationBar_Users{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.7;
    cursor: pointer;
}
.NavigationBar_Users:hover{
    opacity: 1;
}
@media (max-width:768px) {
    .NavigationBar_Users{
        padding-left: 0px;
    }
}
.NavigationBar_Object{
    display: flex;
    justify-content: center;
    align-items: center;
}
.NavigationBar_UserPic{
    border-radius: 50%;
    height: 25px;
    cursor: pointer;
}
.NavigationBar_Downarrow{
    height: 13px;
    margin-bottom: 3px;
    
}
.NavigationBar_ContentInfo{
    grid-area: body;
    
}

.NavigationBar_Signout{
position: absolute;
  top: 45px;
  right: 55px;
  display: block;
  justify-content: flex-end;
  width: 30px;
  opacity: 1;
  z-index: 1;
}
.NavigationBar_User:hover .Signout{
    opacity: 1;

}
.NavigationBar_Modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    
    background-color: rgba(0, 0, 0,0.7);
    z-index: 3;
    transition-property: width;
    transition-duration: 4s;
    transition-timing-function: ease-in;
    transition-delay: 4s;
}

.NavigationBar_NavTab{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    background-color: rgba(243, 239, 239, 1);
    padding-left: 5px;
    justify-content: space-around;
    position: absolute;
    left: 0px;
    z-index: 1;
    margin-bottom: 5px;
    height: 100vh;
    max-width: 200px;
    overflow-y: auto;
}
.NavigationBar_NavTab img{
    height: 25px;
}
.NavigationBar_NavTab a{
    text-decoration: none ;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.452);
    margin-bottom: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: solid rgba(0,0,0,0.3);    
}
.NavigationBar_NavTab a:first-child{
    margin-top: 20px;
}
@media (max-width:768px) {
    .NavigationBar_NavTab a{
       
        padding: 0 1px;
        
         
    }
}
@media (max-width:768px) {
    .NavigationBar_NavTab a img{
        height: 15px;
         
    }
}
.NavigationBar_NavTab a a{
    border-top: none;
    margin-top: 5px;
    
}
.NavigationBar_NavTab a Button{
    text-decoration: none ;
    font-size: 15px;
    display: flex;
    padding: 0 5px;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.452);
    margin-bottom: 5px;
    align-items: center;
    justify-content: flex-start;
    width: 180px;
    border-top: rgba(0, 0, 0,0.5);
}

.NavigationBar_Home{
    border-top: solid rgba(0,0,0,1);
    
}
.NavigationBar_NavTab a Button:hover {
    text-decoration: none;
    background-color: rgba(228,228,228,0.08);
    color: rgba(0, 0, 0, 0.8);
    transform: translate3d(0,-2px,0);
    border-radius: 5px;
  
  }

@media (max-width:768px) {
    .NavigationBar_NavTab{
        padding-left: 5px;
    }
}
