.FriendsPage_container{
  display: flex;
  width: 100%;
  height: 100%;
  
  
}
.FriendsPage_listOfUsers{
  min-width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-color: rgba(255, 245, 222,0.8);
  border-right: 3px solid rgba(255, 245, 222,1);
}
.FriendsPage_listOfUsers h2{
  margin:15px auto;
}
.FriendsPage_chatArea{
  margin-right: 5px;
  min-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: rgba(255, 245, 222,0.8);
  
  
}

.FriendsPage_chatControls{
  display: flex;
  position: fixed;
  width: 70%;
  height: 50px;
  bottom: 0;
}
.FriendsPage_chatControls textarea{
  width: 90%;
}
.FriendsPage_chatControls button{
  width: 10%;
}

.FriendsPage_displayName{
  display: flex;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.FriendsPage_displayName.active{
  background: #ccc;
}

.FriendsPage_displayName .FriendsPage_displayPic{
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 25px;
}

.FriendsPage_displayName .FriendsPage_displayPic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.FriendsPage_messageStyle{
  background: skyblue;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 5px;
  display: inline-block;
}

.FriendsPage_messageSections{
  height: calc(100% - 90px);
  width: 100%;
  overflow-x: hidden;
  position: relative;
  top: 40px;
}

.FriendsPage_onlineStatus{
  display: inline-block;
  margin-top: 10px;
  margin-left: 15px;
  width: 10px;
  height: 10px;
  background: #16e316;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #16e316;
}

.FriendsPage_onlineStatusoff{
  display: inline-block;
  width: 10px;
  margin-top: 10px;
  margin-left: 15px;
  height: 10px;
  background: green;
  border-radius: 5px;
  box-shadow: 0 0 0 0;
}
.FriendsPage_addIcon{
  display: inline-block;
  width: 10px;
  margin-top: 0px;
  margin-left: 15px;
  height: 30px;
  width: 30px;
  z-index: 3;
}