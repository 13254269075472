.main{
    background-color: rgba(255, 245, 222,0.9);
  
        text-align: center;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        
        background-color: rgba(0 0 0 15%);
        
        border-radius: 5px;
        position: relative;
        border: none;
        min-height: 100%;
        flex-grow: 1;
        
      
}
.Header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 25px;
}
.colone{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}
.coltwo{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.rowone{
    display: flex;
    justify-content: center;
    margin: 15px 5px;
}
.FirstName{
    display: flex;
    justify-content: center;
    margin: 15px 5px;
}
.LastName{
    display: flex;
    justify-content: center;
    margin: 15px 5px;
}
.Email{
    display: flex;
    justify-content: center;
    margin: 15px 5px;
}
.Number{
    display: flex;
    justify-content: center;
    margin: 15px 5px;
}

.User{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    justify-content: center;
    margin: 0px 0 10px 0;
    
}
.User img{
    border-radius: 50%;
    height: 100px;
}

@media (max-width:768px) {
    .User{
        padding-left: 0px;
        padding-right: 5px;
    }
}

@media (max-width:768px) {
    .User img{
        height: 70px;
    }
}
.Submit{
    position: fixed;
    bottom: 20px;
    right: 25px;
}
