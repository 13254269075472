.PieChart_Layouts{
  display: grid;

  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  grid-template-rows: minmax(0, 50%) minmax(0, 50%);
  grid-template-areas:
  "status status"
  "status status";
  column-gap: 0px;
  row-gap: 5px;
  height: 100%;
}
@media (max-width: 768px) {
  .PieChart_Layouts {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    margin: 0 0px;
    
  }
}

.PieChart_Status {
  margin-top: 5px;
  grid-area: status;
  display: flex;
  max-height: 100%;
  
}

.PieChart_itemb{
    grid-area: Status;
}

  .PieChart_StatusCard{
    text-align: center;
    overflow-y: auto;
    display: flex;
    margin: 10px 5px;
    padding-bottom: 5px;
    height: 100%;
    justify-content: space-around;
    min-width: 100%;
    

  }
  @media (max-width: 768px) {
    .PieChart_StatusCard {
      max-height: 300px;
      
    }
  }
  .PieChart_StatusCard Button{
    display: flex;
    align-items: flex-start;
    
    
  }
  .PieChart_CommonCardss {
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    border: none;
    max-height: 100%;
    max-width: 300px;
    border-right: solid rgba(0,0,0,0.15);
    
  }
  .PieChart_pieCharts{
    margin-right: 5px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px){
    .PieChart_pieCharts{
    margin: auto;
    }
  }
 